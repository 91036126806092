import React, { useState, useEffect } from 'react';
import { GamePage } from '../modules/GamePage';
import { graphql } from 'gatsby';
import { Router as MyRouter } from '@reach/router';

const Router = props => {

  return (
    <MyRouter>
      <GamePage path="/app/:game" currentGame={props.data.homeJson.gallery} />
    </MyRouter>
  );
};

export default Router;

export const query = graphql`
  query gameQuery {
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      gallery {
        title
        url
        platforms
        description
        weburl
      }
    }
  }
`;
