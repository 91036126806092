import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Title = styled.h1`
  display: block;
  font-size: 4rem;
  font-weight: 500;
  margin: 2rem 2rem 1rem;
  text-transform: uppercase;
`;

export const Descriprion = styled.h2`
  color: #757575;
  margin: 0 2rem 2rem;
  text-align: center;

  ${MEDIA.TABLET`
    margin-bottom: 4rem;
  `};
`;
export const Image = styled.div`
  width: 100%;
  margin: 0 auto;
  border-radius: 8px;
  height: 500px;
  background: #fff no-repeat center;
  background-size: cover;
  border-radius: 8px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.03);
`;
export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
export const BackButton = styled.div`
  position: fixed;
  width: 130px;
  height: 60px;
  background: #ddd;
  text-transform: uppercase;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.03);
  left: 10px;
  top: 150px;
`;
export const GameTreeButton = styled.div`
  background: rgb(85, 170, 227);
  background: linear-gradient(
    90deg,
    rgba(85, 170, 227, 1) 0%,
    rgba(168, 0, 153, 1) 100%
  );
  color: #fff;
  border-radius: 10px;
  border: 1px solid rgb(209, 209, 209);
  font-size: 26px;
  text-decoration: none;
  padding: 10px;
  height: 40px;
  min-width: 40%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;
