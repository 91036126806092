import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Title, Descriprion, Image, Container, BackButton, GameTreeButton } from './GamePageCss';
import { Link } from 'gatsby';
import { IoIosArrowBack } from 'react-icons/io';
import GlobalStyle from '../global.css.js';




const bannerBaseUri = 'https://dxscfd0n17jvm.cloudfront.net/gamesb/';
const defaultBanner = require('../images/game_banner_placeholder.png');

interface Props
  extends RouteComponentProps<{
    res: {},
  }> {}

export const GamePage: React.FC<Props> = (res) => {
  const [currentGame, setGame] = useState({});

  useEffect(() => {
  });
  function getImageUri(uri) {
    if (uri.match(/http(s?):\/\//g)) {
      return uri;
    }

    return bannerBaseUri + uri;
  }


  let state = res.location.state ? res.location.state : res.currentGame.find(game => {
    game.title.toLowerCase() == res.game
    if (game.title.toLowerCase()== res.game) {
      return game
    }
  })

  let bgImage = {
    backgroundImage: state.url ? "url(".concat(getImageUri(state.url), ")") : "url(".concat(defaultBanner, ")")
  };
  // let bgImageImg = {
  //   backgroundImage: state.url ? "url(".concat(getImageUri(state.url), ")") : "url(".concat(defaultBanner, ")")
  // };
  return (
    <Container>
      <GlobalStyle />
      <Link to="/">
        <BackButton as="h2"> <IoIosArrowBack /> Back </BackButton>
      </Link>
      <div style={{width: '70%', margin: '20px auto'}}>
      <img alt={state.title} style={{width: '100%', borderRadius: '10px', boxShadow: '0 0 10px rgba(0,0,0,0.5)'}} src={bannerBaseUri + state.url} />
      </div>

      <Title>{state.title}</Title>
      <Descriprion>{state.platforms.join(', ')}</Descriprion>
      <Descriprion>{state.description}</Descriprion>
      <a style={{textDecoration: 'none'}} target="_blank" href="http://onelink.to/xwe2hd">
        <GameTreeButton> 
          {state.platforms == 'tabletop' ? `Find Local ${state.title} Players` : `Find ${state.title} Players`}
        </GameTreeButton>
        </a>
    </Container>
  );
};
